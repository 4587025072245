<template>
  <div class="modalTracker">
    <base-modal
        :clickToClose="false"
        :name="modalName"
        :scrollable="true"
        :show="!loadingBody"
        @beforeClose="beforeClose"
        @beforeOpen="beforeOpen"
        height="auto"
        transition="el-fade-in"
        width="90%"
    >
      <template v-show="showModal">
        <template slot="header">
          <div class="w-100 d-flex justify-content-between">
            <template v-if="getApiName !== 'create'">
              <div>
                <div class="modalTracker--header--title">#{{ data.id }} {{ data.subject }}</div>
                <div class="modalTracker--header--subTitle">
                  <router-link
                      :key="project.id"
                      :to="`/project/${project.id}`"
                      v-for="(project, idx) in data.pathWithNames"
                  >
                    <span class="subtitle__link_hover">{{ project.name }}</span>
                    <template v-if="idx < data.pathWithNames.length-1 || (data && data.parent_name)">
                      &#10132;
                    </template>
                  </router-link>
                  <span
                      @click="openIssue(data.parent_id)"
                      class="subtitle__link_hover"
                      style="cursor: pointer; color: #C8EBFB"
                      v-if="data && data.parent_name"
                  >
                    {{ data.parent_name }}
                  </span>
                </div>
              </div>
              <div
                  class="mr-5 d-flex align-items-center"
                  style="font-size: 25px"
              >
                <div
                    :class="indexOfCurrentIssueId > 0 ? 'pointer' : ''"
                    :style="{color: indexOfCurrentIssueId <= 0 ? '#b3b3b3' : ''}"
                    @click="toPrevIssue"
                    class="mr-2"
                >&#9664;
                </div>
                <div
                    :class="indexOfCurrentIssueId + 1 !== getIssuesIds.length ? 'pointer' : ''"
                    :style="{color: indexOfCurrentIssueId + 1 === getIssuesIds.length ? '#b3b3b3' : ''}"
                    @click="toNextIssue"
                >&#9654;
                </div>
              </div>
              <!--            </div>-->
            </template>
            <div v-else>
              <div class="modalTracker--header--title"> {{
                  getIdTracker ? $t('system.create_sub_issue') + ' #' +
                      getIdTracker : $t(`system.create_${trackerName}`)
                                                        }}
              </div>
              <!--<div class="modalTracker&#45;&#45;header&#45;&#45;title">{{$t('system.create_issue')}}</div>-->
            </div>
          </div>
        </template>
        <template slot="body">
          <el-form class="container-fluid">
            <!--          <transition name="el-fade-in" appear>-->
            <!--          <div v-if="(schema.containers && schema.containers.length > 0) || (schema.menu && schema.menu.length > 0)">-->
            <form-generator
                :backend_errors="backend_errors"
                :data="data"
                :dictionaries="dictionaries"
                :inputRequiredEmpty="inputRequiredEmpty"
                :mode="mode"
                :schema="schema.menu"
                v-if="schema && schema.menu"
            >
              <template slot="staticScope">
                <div
                    class="col bg-light ml-auto pt-3"
                    v-if="getApiName === 'read'"
                >
                  <div class="d-flex justify-content-end align-items-center">
                    <el-button
                        @click.native="goToEdit"
                        type="info"
                        v-if="ifHasPermission(['pm-edit-issues'])"
                    >
                      {{ $t('issue.edit') }}
                    </el-button>
                    <el-dropdown
                        class="d-flex"
                        trigger="click"
                        v-if="ifHasPermission(['pm-delete-issues']) || ifHasPermission(['pm-create-issues'])"
                    >
                      <i
                          class="el-icon-more"
                          style="font-size: 24px; transform: rotate(90deg); cursor: pointer"
                      ></i>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            @click.native="createSubTracker"
                            v-if="ifHasPermission(['pm-create-issues'])"
                        >
                          {{ $t('issue.create') }}
                        </el-dropdown-item>
                        <el-dropdown-item
                            @click.native="deleteTracker"
                            v-if="ifHasPermission(['pm-delete-issues'])"
                        >
                          {{ $t('issue.delete') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </template>
            </form-generator>
            <form-generator
                :backend_errors="backend_errors"
                :data="data"
                :dictionaries="dictionaries"
                :inputRequiredEmpty="inputRequiredEmpty"
                :mode="mode"
                :schema="schema.containers"
                @hook:mounted="showModal = true"
                v-if="schema && schema.containers"
            >
            </form-generator>
            <div
                class="row"
                v-if="getApiName !== 'read'"
            >
              <div
                  :class="schema && schema.containers && schema.containers[schema.containers.length - 1] && schema.containers[schema.containers.length - 1].class ? schema.containers[schema.containers.length - 1].class : ''"
                  class="ml-auto"
              >
                <div class="d-flex justify-content-end mb-3">
                  <el-button
                      :loading="saveOrUpdateLoading"
                      @click.prevent.stop="onSaveOrUpdate"
                      type="primary"
                  >
                    {{ getApiName === 'create' ? $t('system.create') : $t('system.update') }}
                  </el-button>
                  <el-button
                      @click.native="goToShow"
                      v-if="getApiName === 'edit'"
                  >{{ $t('system.cancel') }}
                  </el-button>
                  <el-button
                      @click.native="beforeClose"
                      v-else
                  >{{ $t('system.cancel') }}
                  </el-button>
                </div>
              </div>
            </div>
            <!--          </div>-->
            <!--          </transition>-->
          </el-form>
        </template>
        <template slot="footer" />
      </template>
    </base-modal>
  </div>

</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import BaseModal from '../BaseModal.vue';
import notification from '../../../notification/notify';
import formGenerator from '../../vue-form-generator/FromGenerator.vue';
import EventBus from '../../../eventBus';
import projectTreeBuilder from '../../../mixin/projectsTreeBuilder';
import 'element-ui/lib/theme-chalk/base.css';
import issue from '../../../mixin/issue';
import {HHHmmToHHmmss} from '@/helpers/time';

export default {
  name: 'IssueModal',
  mixins: [projectTreeBuilder, issue],
  props: {},
  components: {
    BaseModal,
    formGenerator,
  },
  data() {
    return {
      transition: 'el-fade-in',
      modalName: 'tracker',
      inputRequiredEmpty: [],
      backend_errors: {},
      data: {},
      dictionaries: {},
      schema: [],
      loadingBody: true,
      saveOrUpdateLoading: false,
      mode: '',
      showModal: false,
    };
  },
  async mounted() {
    EventBus.$on('FETCH_DATA', () => {
      this.fetchData(['data']);
    });
    EventBus.$on('LOADING', (val) => {
      this.saveOrUpdateLoading = val;
    });
    if (this.$route.query.issueId) {
      this.$modal.show(this.modalName);
      await this.fetchData();
    }
  },
  beforeDestroy() {
    EventBus.$off('FETCH_DATA');
    EventBus.$off('LOADING');
    EventBus.$off('FILES_UPLOADED');
  },
  computed: {
    ...mapGetters('modal', [
      'getIdTracker',
      'getTypeTracker',
      'getApiName',
      'getParentId',
      'getProjectId',
      'trackerName',
      'getIssuesIds',
    ]),
    indexOfCurrentIssueId() {
      return this.getIssuesIds.indexOf(this.getIdTracker);
    },
  },
  watch: {
    getApiName(newValue, oldValue) {
      if (newValue) {
        this.$modal.show(this.modalName);
        if (oldValue || newValue === 'create') {
          this.fetchData();
        }
      } else {
        this.$modal.hide(this.modalName);
      }
    },
    '$route.query.issueId'(newVal) {
      if (newVal) {
        this.$store.commit('modal/OPEN_READ_MODAL', parseInt(newVal));
        this.$modal.show(this.modalName);
        this.fetchData();
      } else {
        this.$store.dispatch('modal/closeModal');
        this.$modal.hide(this.modalName);
      }
    },
  },
  methods: {
    ...mapMutations([
      'modal/CLOSE_MODAL',
    ]),
    ...mapActions('permission', ['setProjectId']),
    toNextIssue() {
      this.transition = 'out-left';
      if (this.indexOfCurrentIssueId + 1 !== this.getIssuesIds.length) {
        this.openIssue(this.getIssuesIds[this.indexOfCurrentIssueId + 1]);
      }
    },
    toPrevIssue() {
      this.transition = 'out-right';
      if (this.indexOfCurrentIssueId > 0) {
        this.openIssue(this.getIssuesIds[this.indexOfCurrentIssueId - 1]);
      }
    },
    existsFileUploadComponent() {
      let result = false;
      this.schema.containers.forEach((column) => {
        column.fields.forEach((item) => {
          if (item.type === 'files') {
            result = true;
          }
        });
      });
      return result;
    },
    async fetchData(options = []) {
      this.loadingBody = true;
      this.mode = this.getApiName;
      let headers = {
        platform: 'web',
        mode: this.getApiName,
      };
      if (this.getApiName === 'create') {
        await axios.get(`issue/create`, {
          params: {
            tracker_id: this.getTypeTracker,
            project_id: this.getProjectId ? this.getProjectId : this.$store.getters.currentProjectId,
          },
          headers: headers,
        }).then((response) => {
          // this.$modal.show(this.nameModal);
          this.data = response.data.data;
          this.data.project_id = this.getProjectId
                                 ? parseInt(this.getProjectId)
                                 : this.$store.getters.currentProjectId
                                   ? parseInt(this.$store.getters.currentProjectId)
                                   : null;
          this.schema = response.data.schema;
          if (this.data && this.data.parent_id && this.getParentId) {
            this.data.parent_id = this.getParentId;
            this.schema.containers.forEach((container) => {
              container.fields.forEach((field) => {
                if (field.dbName === 'project_id') {
                  field.meta.disabled = true;
                }
              });
            });
          }
          this.dictionaries = response.data.dictionaries;
          if (this.dictionaries && this.dictionaries.projects) {
            this.dictionaries.projects = this.buildProjectTree(this.dictionaries.projects);
          }
          this.loadingBody = false;
        }).catch((error) => {
          this.beforeClose();
          console.log('error', error.data.message);
        });
      } else {
        await axios.get(`issue/${this.getIdTracker}`, {
          params: {
            project_id: this.$route.params.projectId,
            //TODO uncomment after fix backend
            // options: options
          },
          headers: headers,
        }).then((response) => {
          if (options.length > 0) {
            options.forEach((option) => {
              if (typeof this[option] !== 'undefined') {
                this[option] = response.data[option];
              }
            });
          } else {
            Object.keys(response.data).forEach((key) => {
              if (typeof this[key] !== 'undefined') {
                this[key] = response.data[key];
              }
            });
          }
          this.setProjectId(this.data.project_id);
          if (this.data.parent_id) {
            this.schema.containers.forEach((container) => {
              container.fields.forEach((field) => {
                if (field.dbName === 'project_id') {
                  field.meta.disabled = true;
                }
              });
            });
          }
          if ((options.length === 0 || options.dictionaries) && this.dictionaries && this.dictionaries.projects) {
            this.dictionaries.projects = this.buildProjectTree(this.dictionaries.projects);
          }
          this.loadingBody = false;
        }).catch((error) => {
          notification.notify(
              this.$t('notify.error'),
              this.$t(error.response.data.error),
              'error');
          this.beforeClose();
        });
      }
    },
    closeByEsc(e) {
      if (e.keyCode === 27) {
        this.beforeClose();
      }
      // else if (e.keyCode === 39) {
      //   this.toNextIssue()
      // } else if (e.keyCode === 37) {
      //   this.toPrevIssue()
      // }
    },
    beforeOpen() {
      document.addEventListener('keyup', this.closeByEsc);
    },
    beforeClose(event) {
      this.transition = 'el-fade-in';
      document.removeEventListener('keyup', this.closeByEsc);
      this.closeModal();
      EventBus.$emit('CLOSE_ISSUE_MODAL', this.$route.query.issueId);
      this.inputRequiredEmpty = [];
      this.backend_errors = {};
      this.data = {};
      this.dictionaries = {};
      this.schema = [];
    },
    closeModal() {
      let query = {...this.$route.query};
      delete query.issueId;
      this.$router.push({path: this.$route.path, query: query}).catch(() => {
      });
      this.$store.commit('issue/UPDATE_LIST_ISSUE', true);
      this.$store.commit('modal/CLOSE_MODAL');
    },
    goToShow() {
      let query = {...this.$route.query};
      this.$store.commit('modal/OPEN_READ_MODAL', query.issueId);
    },
    goToEdit() {
      let query = {...this.$route.query};
      this.$store.commit('modal/OPEN_UPDATE_MODAL', query.issueId);
    },
    createSubTracker() {
      this.$store.commit('modal/OPEN_CREATE_SUB_MODAL', this.data);
    },
    async deleteTracker() {
      //check if issue has active loggers
      // const swal = await this.importSwal();
      swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      }).then((resp) => {
        if (resp.value) {
          axios.delete(`/issue/${this.$route.query.issueId}`)
              .then((response) => {
                // this.$store.commit('issue/UPDATE_LIST_ISSUE', true);
                this.beforeClose();
                notification.notify(
                    this.$t('notify.success'),
                    this.$t('system.deleted'),
                    'success');
              })
              .catch((error) => {
                notification.notify(
                    this.$t('notify.error'),
                    this.$t(error.message),
                    'error');
              });
        }
      });
    },
    onSaveOrUpdate() {
      let headers = {
        platform: 'web',
        mode: this.getApiName,
      };
      this.checkValidation().then(() => {
        this.saveOrUpdateLoading = true;
        let method = this.getApiName === 'create' ? 'post' : 'put';
        const address = method === 'post' ? 'issue' : `issue/${this.getIdTracker}`;
        const formClone = {...this.data};
        if (this.getParentId) {
          formClone.parent_id = this.getParentId;
        }
        if (this.data.estimated_hours) {
          formClone.estimated_hours = HHHmmToHHmmss(this.data.estimated_hours);
        }
        axios[method](address, formClone, {params: {tracker_id: this.getTypeTracker}, headers: headers})
            .then(async (response) => {
              let query = {...this.$route.query};
              const id = response.data.id;
              query.issueId = id;
              // TODO send files to backend
              try {
                if (this.existsFileUploadComponent()) {
                  EventBus.$emit('ISSUE_WAS_CREATED_OR_UPDATED', id);

                  let handleFileUpload = new Promise((resolve, reject) => {
                    EventBus.$on('FILES_UPLOADED', () => {
                      resolve();
                    });
                  });
                  await handleFileUpload;
                }
                this.saveOrUpdateLoading = false;
                this.$router.push({path: this.$route.path, query: query});
                this.$store.commit('modal/OPEN_READ_MODAL', id);
                notification.notify(
                    this.$t('notify.success'),
                    this.$t(response.data.message),
                    'success');
              } catch (error) {
                this.saveOrUpdateLoading = false;
                notification.notify(
                    this.$t('notify.error'),
                    this.$t(error.data.message),
                    error.type);
              }
            })
            .catch((error) => {
              this.saveOrUpdateLoading = false;
              notification.notify(
                  this.$t('notify.error'),
                  this.$t(error.data.message),
                  error.type);
            });
      }).catch(() => {
        this.saveOrUpdateLoading = false;
      });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.modalTracker--header--title {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.modalTracker--header--subTitle {
  color: #bdbdbd;

  .subtitle__link {
    &_hover:hover {
      text-decoration: underline;
    }
  }
}
</style>
